import { LocalStorageService } from '../../shared/services/localStorage.service';
import { setSelectedOrders } from '../../store/orders';
import { PlaceBySupplierCartReq } from '../cart/types';
import { getOrdersListQueryParamsHelper, setSuccessToast } from '../helpers';
import { HTTP, rootApi, SuccessResponse } from '../index';
import {
  AddItemNoteReq,
  AddMultipleItemNotesReq,
  CreateOrderRequest,
  CreateOrderResponse,
  ExportInvoiceResponse,
  ExportOrdersRequest,
  ExportOrdersResponse,
  ExportPickingSlipResponse,
  FinishOrderRequestParams,
  FinishOrderResponse,
  GetOrderByIdWithSortRequest,
  GetOrdersListReqParams,
  Order,
  OrderItemsActionRequest,
  OrdersListResponse,
  SaveOrderNoteResponse,
  UndoExportOrdersReq,
  UpdateOrderItemsRequest,
} from './types';

export const orderApi = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    getOrders: builder.query<OrdersListResponse, GetOrdersListReqParams>({
      query: (args) => {
        const params = {
          ...getOrdersListQueryParamsHelper(args),
          per_page: 30,
          page: args?.currentPage || 1,
        };
        return {
          url: `/orders`,
          method: HTTP.GET,
          params,
        };
      },
      keepUnusedDataFor: 0,
      providesTags: ['Order'],
    }),

    getOrderById: builder.query<Order, number | void>({
      query: (id) => {
        return {
          url: `/orders/${id}/get_order`,
          method: HTTP.GET,
        };
      },
      providesTags: ['Order_By_Id'],
    }),

    getOrderByIdWithSorts: builder.query<Order, GetOrderByIdWithSortRequest>({
      query: ({ id, ...params }) => {
        return {
          url: `/orders/${id}/get_order`,
          method: HTTP.GET,
          params,
        };
      },
      providesTags: ['Order_By_Id'],
    }),

    saveOrder: builder.mutation<Order, GetOrderByIdWithSortRequest>({
      query: ({ id, ...body }) => {
        return {
          url: `/orders/${id}/save_order`,
          method: HTTP.POST,
          body,
        };
      },
      invalidatesTags: ['Order', 'Order_By_Id', 'Products_Purchased', 'Delivery_List'],
    }),

    exportOrders: builder.query<ExportOrdersResponse, ExportOrdersRequest>({
      query: (params) => {
        return {
          url: `/export_orders`,
          method: HTTP.GET,
          params,
        };
      },
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(rootApi.util.invalidateTags(['Orders_Count', 'Order', 'Me', 'Order_By_Id', 'Products_Purchased', 'Delivery_List']));
        } catch (e) {
          console.log(e);
        }
      },
      keepUnusedDataFor: 0,
    }),

    exportPickingSlip: builder.query<ExportPickingSlipResponse, number>({
      query: (id) => {
        return {
          url: `/orders/${id}/picking_slip_pdf`,
          method: HTTP.GET,
        };
      },
      keepUnusedDataFor: 0,
    }),

    createOrder: builder.mutation<CreateOrderResponse, CreateOrderRequest>({
      query: (args) => {
        const params: PlaceBySupplierCartReq = {};
        if (LocalStorageService.getItem('impersonated_customer')) {
          params.place_by_supplier = true;
          params.customer_id = LocalStorageService.getItem('impersonated_customer')?.customer_id;
        }
        return {
          url: `/orders`,
          method: HTTP.POST,
          body: { ...args, ...params },
        };
      },
      invalidatesTags: ['Inventories', 'Run_Out_Product'],
    }),

    deleteOrder: builder.mutation<SuccessResponse, { id: number; send_email: boolean }>({
      query: ({ id, ...body }) => {
        return {
          url: `/orders/${id}`,
          method: HTTP.DELETE,
          body,
        };
      },
      invalidatesTags: ['Order', 'Order_By_Id', 'Products_Purchased', 'Orders_Count'],
    }),

    removeOrderItem: builder.mutation<SuccessResponse, OrderItemsActionRequest>({
      query: ({ id, skipInvalidation, ...body }) => {
        return {
          url: `/orders/${id}/remove_order_item`,
          method: HTTP.DELETE,
          body,
        };
      },
      invalidatesTags: (res, _, args) => (args.skipInvalidation ? [] : ['Order', 'Order_By_Id', 'Products_Purchased', 'Orders_Count']),
    }),

    backOrder: builder.mutation<SuccessResponse, OrderItemsActionRequest>({
      query: ({ id, skipInvalidation, ...body }) => {
        return {
          url: `/orders/${id}/backorder_order_item`,
          method: HTTP.PATCH,
          body,
        };
      },
      invalidatesTags: (res, _, args) => (args.skipInvalidation ? [] : ['Order', 'Order_By_Id', 'Products_Purchased']),
    }),

    undoExportOrder: builder.mutation<SuccessResponse, UndoExportOrdersReq>({
      query: (body) => {
        return {
          url: `/undo_exported_orders`,
          method: HTTP.PATCH,
          body,
        };
      },
      invalidatesTags: ['Order', 'Order_By_Id', 'Products_Purchased', 'Orders_Count'],
    }),

    substituteOrder: builder.mutation<SuccessResponse, OrderItemsActionRequest>({
      query: ({ id, skipInvalidation, ...body }) => {
        return {
          url: `/orders/${id}/substitute_order_item`,
          method: HTTP.PATCH,
          body,
        };
      },
      invalidatesTags: (res, _, args) => (args.skipInvalidation ? [] : ['Order', 'Order_By_Id', 'Products_Purchased']),
    }),

    updateOrderDeliveryDate: builder.mutation<
      SuccessResponse,
      {
        id: number;
        date: string;
        skipInvalidation?: boolean;
      }
    >({
      query: ({ id, skipInvalidation, ...body }) => {
        return {
          url: `/orders/${id}/update_delivery_date`,
          method: HTTP.PATCH,
          body,
        };
      },
      invalidatesTags: (res, _, args) => (args.skipInvalidation ? [] : ['Order', 'Order_By_Id', 'Products_Purchased']),
    }),

    finishOrder: builder.query<FinishOrderResponse, FinishOrderRequestParams>({
      query: ({ id, ...params }) => {
        return {
          url: `/orders/${id}/finish_order`,
          method: HTTP.GET,
          params,
        };
      },
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(rootApi.util.invalidateTags(['Order_By_Id', 'Products_Purchased', 'Me', 'Orders_Count', 'Delivery_List']));
        } catch (e) {
          console.log(e);
        }
      },
      keepUnusedDataFor: 0,
    }),

    updateOrderItems: builder.mutation<SuccessResponse, UpdateOrderItemsRequest>({
      query: ({ id, skipInvalidation, ...body }) => {
        return {
          url: `/orders/${id}/update_multiple_order_items`,
          method: HTTP.PATCH,
          body,
        };
      },
      invalidatesTags: (res, _, args) => (args.skipInvalidation ? [] : ['Order', 'Order_By_Id', 'Products_Purchased']),
    }),

    updateOrderNumber: builder.mutation<SuccessResponse, { purchase_number: string; id: number }>({
      query: ({ id, ...body }) => {
        return {
          url: `/orders/${id}/update_order_number`,
          method: HTTP.PATCH,
          body,
        };
      },
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          orderApi.util.updateQueryData('getOrderById', args.id, (draft) => {
            draft.number = args.purchase_number;
          }),
        );
        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
        }
      },
    }),

    addOrderItemNote: builder.mutation<SaveOrderNoteResponse, AddItemNoteReq>({
      query: ({ id, ...body }) => {
        return {
          url: `/orders/${id}/item_note`,
          method: HTTP.PATCH,
          body,
        };
      },
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        let patchResult;
        try {
          const res = await queryFulfilled;
          patchResult = dispatch(
            orderApi.util.updateQueryData('getOrderById', args.id, (draft) => {
              const updatedProductIdx = draft.order_items.findIndex((el) => el.id === args.item_id);
              const dataToSetIdx = res.data.order.order_items.findIndex((el) => el.id === args.item_id);
              draft.order_total = res.data.order.order_total;
              draft.sub_total = res.data.order.sub_total;
              draft.order_delivery_fee = res.data.order.order_delivery_fee;
              draft.tax = res.data.order.tax;
              if (updatedProductIdx !== -1 && dataToSetIdx !== -1) {
                draft.order_items[updatedProductIdx] = res.data.order.order_items[dataToSetIdx];
              }
            }),
          );
        } catch {
          patchResult?.undo();
        }
      },
    }),

    addOrderMultipleItemNotes: builder.mutation<SuccessResponse, AddMultipleItemNotesReq>({
      query: ({ id, ...body }) => {
        return {
          url: `/orders/${id}/update_items_note`,
          method: HTTP.PATCH,
          body,
        };
      },
    }),

    addOrderNoteForVariation: builder.mutation<SaveOrderNoteResponse, { id: number; note: string }>({
      query: ({ id, ...body }) => {
        return {
          url: `/orders/${id}/note_for_variation`,
          method: HTTP.PATCH,
          body,
        };
      },
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          orderApi.util.updateQueryData('getOrderById', args.id, (draft) => {
            draft.comment = args.note;
          }),
        );
        try {
          await queryFulfilled;
        } catch {
          patchResult?.undo();
        }
      },
    }),

    checkOffOrder: builder.mutation<
      SuccessResponse,
      { order_id: number; request_credit?: boolean; request_amendment?: boolean; confirmed_items_id: string }
    >({
      query: (body) => {
        return {
          url: `/order_check_off`,
          method: HTTP.PATCH,
          body,
        };
      },
      invalidatesTags: ['Order_By_Id', 'Inventories', 'Run_Out_Product'],
    }),

    reOrder: builder.mutation<SuccessResponse,{ order_id: number }>({
      query: (body) => {
        return {
          url: `/re_order`,
          method: HTTP.PATCH,
          body,
        };
      }
    }),

    confirmCheckOffAll: builder.mutation<SuccessResponse, number>({
      query: (order_id) => {
        return {
          url: `/confirm_all_items`,
          method: HTTP.PATCH,
          body: { order_id },
        };
      },
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          orderApi.util.updateQueryData('getOrderById', args, (draft) => {
            draft.order_items.forEach((el) => (el.confirmed = true));
          }),
        );
        try {
          await setSuccessToast(dispatch, queryFulfilled, `All products confirmed`);
        } catch {
          patchResult.undo();
        }
      },
      invalidatesTags: ['Order_By_Id', 'Inventories', 'Run_Out_Product'],
    }),

    acceptCheckOffOrder: builder.mutation<SuccessResponse, { order_id: number; accept_request: boolean }>({
      query: (body) => {
        return {
          url: `/accept_or_declined`,
          method: HTTP.PATCH,
          body,
        };
      },
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        await setSuccessToast(dispatch, queryFulfilled, args.accept_request ? 'Order has been completed' : 'Declined');
      },
      invalidatesTags: ['Order_By_Id'],
    }),

    exportInvoice: builder.query<ExportInvoiceResponse, { order_ids: string; csv?: boolean }>({
      query: (params) => {
        return {
          url: `/export_invoice`,
          method: HTTP.GET,
          params,
        };
      },
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(rootApi.util.invalidateTags(['Order']));
        } catch (e) {
          console.log(e);
        }
      },
      keepUnusedDataFor: 0,
    }),

    deleteMultipleOrders: builder.mutation<
      SuccessResponse,
      {
        order_ids: number[];
        send_email: boolean;
        deleted?: boolean;
      }
    >({
      query: (body) => {
        return {
          url: `/orders/destroy_multiple`,
          method: HTTP.PATCH,
          body,
        };
      },
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        await setSuccessToast(
          dispatch,
          queryFulfilled,
          `${args.order_ids.length} orders have been ${args.deleted ? 'deleted' : 'restored'}`,
        );
        dispatch(setSelectedOrders([]));
      },
      invalidatesTags: ['Order', 'Order_By_Id', 'Products_Purchased', 'Orders_Count'],
    }),
  }),

  overrideExisting: true,
});

export const {
  useCreateOrderMutation,
  useGetOrdersQuery,
  useGetOrderByIdQuery,
  useDeleteOrderMutation,
  useRemoveOrderItemMutation,
  useBackOrderMutation,
  useSubstituteOrderMutation,
  useUpdateOrderItemsMutation,
  useLazyExportOrdersQuery,
  useLazyExportPickingSlipQuery,
  useGetOrderByIdWithSortsQuery,
  useSaveOrderMutation,
  useUndoExportOrderMutation,
  useLazyFinishOrderQuery,
  useUpdateOrderDeliveryDateMutation,
  useUpdateOrderNumberMutation,
  useLazyExportInvoiceQuery,
  useDeleteMultipleOrdersMutation,
  useAddOrderItemNoteMutation,
  useAddOrderNoteForVariationMutation,
  useCheckOffOrderMutation,
  useAcceptCheckOffOrderMutation,
  useConfirmCheckOffAllMutation,
  useAddOrderMultipleItemNotesMutation,
  useReOrderMutation,
} = orderApi;
